import styled from 'styled-components';
import { FlexRowAlignCenter } from '@components/wrapper';
import { colors } from '@public/theme';

export const ProductFilterWrapper = styled.div`
  border-bottom: 1px solid #f0f0f0;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const SortSelectBox = styled.div`
  padding: 0px 7px;
  display: flex;
  cursor: pointer;
`;

export const SortSelectBoxText = styled(FlexRowAlignCenter)`
  height: 20px;
  font-size: 12px;
  font-weight: 500;
  width: 100px;
  margin-right: 16px;
  justify-content: flex-end;
`;

export const SortDropMenu = styled.div<{ isShow: boolean }>`
  position: absolute;
  z-index: 10;
  background-color: white;
  right: 5px;
  top: 32px;
  border-radius: 4px;
  border: 1px solid ${colors.gray.c1};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  padding: 2px 0px;

  ${(props) =>
    !props.isShow &&
    `
        display: none;
    `}
`;

export const SortItem = styled.div`
  width: 100px;
  padding: 8px 0px;
  text-align: center;

  &:hover {
    background-color: #fafafd;
    cursor: pointer;
    font-weight: bold;
  }
`;
