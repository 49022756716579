import styled from 'styled-components';

export const SearchBrandContainer = styled.div`
  height: 40px;
  background: #fafafc;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  gap: 4px;
`;

export const SearchBrandLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  height: 24px;

  .findCreator {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;
